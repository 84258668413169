<template>
  <div>
    <!-- Data table projects -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>
        <span>Proyectos</span>
        <v-spacer />
        <v-btn
          v-if="projectPermissions.add.includes(user.role) && projectBagEditable.status === 'CREATED'"
          rounded
          small
          @click="handleAddProjectBtn()"
        >
          Añadir proyecto
        </v-btn>
      </v-toolbar>

      <DataTable
        :headers="tableProjectsHeaders"
        :items="projectBagEditable.projects ? projectBagEditable.projects : []"
        :sortDesc="false"
        :hideDefaultFooter="false"
        :disablePagination="false"
      >
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/proyectos/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ currency(item.amount, { fromCents: true }) }}
        </template>
        <template v-if="projectBagEditable.status === 'CREATED'" v-slot:[`item.actions`]="{ item }">
          <v-icon color="red" @click="(selectedProjectToDelete = item), (dialogDeleteProject = true)">delete</v-icon>
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog add project -->
    <DialogBox :model="dialogAddProject" :max_width="'1000'">
      <template slot="toolbar">
        <span>Añadir proyecto</span>
      </template>
      <template slot="content">
        <DataTable
          :headers="tableProjectsToAddHeaders"
          :items="$store.state.projects.projects"
          :totalItems="$store.state.projects.totalCountProjects"
          @clickNextIcon="handleNextIcon"
          @clickPrevIcon="handlePrevIcon"
          @inputSearch="handleSearch"
          @changeItemsPerPage="getProjects"
        >
          <template v-slot:[`item.checkbox`]="{ item }">
            <v-checkbox v-if="projectBagEditable.projects?.some((e) => e.id === item.id)" input-value="true" value disabled />
            <v-checkbox v-else v-model="selectedProjectsToAdd" :value="item.id" hide-details class="mt-0" />
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <router-link :to="`/proyectos/${item.id}`">{{ item.id }}</router-link>
          </template>
          <template v-slot:[`item.borrower`]="{ item }">
            <router-link :to="`/prestatarias/${item.borrower}`">{{ item.borrower.name }}</router-link>
          </template>
          <template v-slot:[`item.technician`]="{ item }">
            <router-link :to="`/tecnicos/${item.technician.id}`">{{ item.technician.name }}</router-link>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ formatDateL(item.created_at) }}
          </template>
        </DataTable>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogAddProject = false">Cerrar</v-btn>
        <v-btn text :disabled="selectedProjectsToAdd.length === 0" @click="addProjects()">Añadir</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog delete project -->
    <DialogBox :model="dialogDeleteProject" :color="'error'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de eliminar este proyecto de la bolsa?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogDeleteProject = false">Cerrar</v-btn>
        <v-btn text @click="(dialogDeleteProject = false), deleteProject()">eliminar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import * as currency from "currency.js";

export default {
  components: {
    DataTable,
    DialogBox
  },
  mixins: [FormatDateMixin],
  props: {
    projectBagEditable: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // Projects datatable
      tableProjectsHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Cantidad", value: "amount", sortable: false },
        { text: "", value: "actions", sortable: false }
      ],

      tableProjectsToAddHeaders: [
        { text: "", value: "checkbox", sortable: false },
        { text: "Id", value: "id", sortable: false },
        { text: "Prestataria", value: "borrower", sortable: false },
        { text: "Técnico", value: "technician", sortable: false },
        { text: "Moneda", value: "currency", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ],

      // Cards actions permissions
      projectPermissions: {
        delete: ["SUPERADMIN", "ADMIN"],
        add: ["SUPERADMIN", "ADMIN"]
      },

      // Project selected
      selectedProjectsToAdd: [],
      selectedProjectToDelete: null,

      // Filters variables
      elasticData: {},

      // Dialogs
      dialogDeleteProject: false,
      dialogAddProject: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    currency() {
      return currency;
    }
  },
  methods: {
    ...mapActions({
      getProjects: "projects/getProjects",
      getProjectBag: "projectBags/getProjectBag"
    }),

    async handlePrevIcon(elasticData) {
      this.elasticData = Object.assign(this.elasticData, elasticData);
      await this.getProjects(this.elasticData);
    },

    async handleNextIcon(elasticData) {
      this.elasticData = Object.assign(this.elasticData, elasticData);
      await this.getProjects(this.elasticData);
    },

    async handleSearch(elasticData) {
      this.elasticData = Object.assign(this.elasticData, elasticData);
      await this.getProjects(this.elasticData);
    },

    async handleAddProjectBtn() {
      this.elasticData = {
        size: 10,
        sort: { created_at: "DESC" },
        filter: { "partner.id": this.projectBagEditable.partner_id, to_bag: true, status: "APPROVED" }
      };
      await this.getProjects(this.elasticData);
      this.dialogAddProject = true;
    },

    async addProjects() {
      await this.$store.dispatch("projectBags/addProjectsBag", {
        id: this.projectBagEditable.id,
        projects_ids: this.selectedProjectsToAdd
      });
      await this.getProjectBag({ filter: { id: this.$route.params.id } });
      this.dialogAddProject = false;
      this.selectedProjectsToAdd = [];
    },

    async deleteProject() {
      await this.$store.dispatch("projectBags/deleteProjectOfProjectBag", {
        id: this.projectBagEditable.id,
        project_id: this.selectedProjectToDelete.id
      });
      await this.getProjectBag({ filter: { id: this.$route.params.id } });
    }
  }
};
</script>
